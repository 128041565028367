<template>


    <div class="loader_box">
        <div class="sk-three-bounce">
    <div class="sk-child sk-bounce-1"  :style="`background-color:${selectedColor};`"></div>
    <div class="sk-child sk-bounce-2"  :style="`background-color:${selectedColor};`"></div>
    <div class="sk-child sk-bounce-3"  :style="`background-color:${selectedColor};`"></div>
  </div>
        </div>
    </template>
    <script>
    export default{
        props:['selectedColor'],
    }
    </script>
    <style scoped>
    .loader_box{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        z-index:999
    }

    .sk-three-bounce {
  width: calc(64px * 2);
  margin: auto;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: calc(64px / 2);
  height: calc(64px / 2);
  /* background-color: var(--spinkit-spinner-color); */
  border-radius: 100%;
  display: inline-block;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce-1 {
  animation-delay: calc(-1 * 0.32s);
}

.sk-three-bounce .sk-bounce-2 {
  animation-delay: calc((-1 * 0.32s) / 2);
}

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

    

    
    </style>